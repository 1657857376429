.paywall-manager__fallback {
  padding: 2em 1em;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 8px rgba(0,0,0,.04), 0 8px 40px rgba(0,0,0,.08);

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  * {
    margin: 0;
  }

  h2 {
    font-size: 2em;
  }

  span {
    display: inline-block;
  }

  div {
    margin-top: 0.5rem;
  }
}
